import { createApp } from "vue";
import App from "./App.vue";

// 引入element-plus
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./styles/element-var.scss"; // 覆盖elemnet主题色
import zhCn from "element-plus/es/locale/lang/zh-cn"; // 采用中文

// 引入router
import router from "./routers";
// 全局路由守卫
import "./helpers/premission";

import store from "./stores";

// 全局style
import "./styles/index.scss";
import components from "./components";

import moment from "moment";

const app = createApp(App);

app.use(ElementPlus, { locale: zhCn, zIndex: 1000 });
app.use(router);
app.use(store);
app.use(components);
app.config.globalProperties.$moment = moment;

app.mount("#app");
