<template>
  <div class="comp-container">
    <div class="input-ctan">
      <el-input v-show="!simple" :model-value="modelValue" disabled></el-input>
      <el-upload
        v-if="!simple || multiple || !urls.length"
        action=""
        :multiple="multiple"
        :http-request="httpHandle"
        accept=".jpg,jpeg,.png"
        :show-file-list="false"
      >
        <el-button class="upload-btn fa fa-upload" type="danger"
          >上传</el-button
        >
      </el-upload>
    </div>
    <div class="imgs-ctan">
      <div class="img-item" v-for="(item, index) in urls" :key="index">
        <el-image
          style="width: 100px; height: 100px"
          :src="item"
          fit="contain"
          :preview-src-list="urls"
          preview-teleported
        ></el-image>
        <span class="remove-btn fa fa-trash" @click="removeHandle(item)"></span>
      </div>
    </div>
  </div>
</template>

<script>
import cos from "@/helpers/cos";
import { remove } from "lodash";
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    simple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      urls: [],
    };
  },
  watch: {
    modelValue: {
      handler(val) {
        this.urls = val ? val.split(",") : [];
      },
      immediate: true,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    httpHandle(file) {
      cos.put(file.file).then(({ Location }) => {
        this.urls.push("https://" + Location);
        this.$emit("update:modelValue", this.urls.join(","));
      });
    },
    removeHandle(item) {
      remove(this.urls, (url) => item === url);
      this.$emit("update:modelValue", this.urls.join(","));
    },
  },
};
</script>

<style lang="scss" scoped>
.comp-container {
  font-size: 13px;
  width: 100%;
  .input-ctan {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;

    .upload-btn {
      // margin-left: 10px;
      margin-right: 0;
    }
  }
  .imgs-ctan {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .img-item {
      position: relative;
      margin-right: 10px;
      margin-bottom: 10px;
      border: 1px solid #e5e5e5;

      .remove-btn {
        position: absolute;
        right: 0;
        top: 0;
        background-color: #f56c6c;
        color: #fff;
        padding: 4px;
        border-bottom-left-radius: 6px;
        cursor: pointer;
      }
      &:hover .remove-btn {
        display: block;
        &:active {
          background-color: mix(#ffffff, #f56c6c, 20%);
        }
      }
    }
  }
}
</style>
