<template>
  <div class="pagination-container">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currPage"
      :page-sizes="BASE_PAGINATION.pageSizes"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
import { BASE_PAGINATION } from "@/helpers/constants";
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          pageSize: BASE_PAGINATION.pageSize,
          currPage: BASE_PAGINATION.currPage,
        };
      },
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      BASE_PAGINATION: BASE_PAGINATION,
      pageSize: BASE_PAGINATION.pageSize,
      currPage: BASE_PAGINATION.currPage,
    };
  },
  emits: ["update:modelValue", "change"],
  created() {
    this.$emit("update:modelValue", {
      pageSize: this.pageSize,
      currPage: this.currPage,
      offset: (this.currPage - 1) * this.pageSize,
      limit: this.pageSize,
    });
  },
  methods: {
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.updateModel();
    },
    handleCurrentChange(currPage) {
      this.currPage = currPage;
      this.updateModel();
    },

    updateModel() {
      this.$emit("update:modelValue", {
        pageSize: this.pageSize,
        currPage: this.currPage,
        offset: (this.currPage - 1) * this.pageSize,
        limit: this.pageSize,
      });
      this.$emit("change", {
        pageSize: this.pageSize,
        currPage: this.currPage,
        offset: (this.currPage - 1) * this.pageSize,
        limit: this.pageSize,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-container {
  margin: 20px 0;
  flex: 1;
  align-items: flex-end;
}
</style>
