import http from "@/helpers/http";
import qs from "qs";
import { packFormUrl,pack } from "@/helpers/utils";
const service = "faShopBox/";
export function getShopboxList(form) {
  return http.request({
    url: "/SYTdknLasJ.php/shopbox/index",
    method: "get",
    params: {
      _: Date.now(),
      ...form,
    },
  });
}

export function addShopbox(form) {
  return http.request({
    url: `/SYTdknLasJ.php/shopbox/add`,
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      ...packFormUrl(form),
      "row[shop_id][]": form.shop_id,
    }),
    params: {
      dialog: 1,
    },
  });
}

export function editShopbox(form) {
  return http.request({
    url: `/SYTdknLasJ.php/shopbox/edit/ids/${form.Id}`,
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      "row[name]": form.name,
      "row[shop_id][]": form.shop_id,
    }),
    params: {
      dialog: 1,
    },
  });
}

export function deleteShopbox(id) {
  return http.request({
    url: "/SYTdknLasJ.php/shopbox/del",
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      action: "del",
      ids: id,
    }),
  });
}



export function listByPage(form) {
	return http.request({
		url: service + "list",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function deleteById(id) {
	return http.request({
		url: service + "deleteById",
		method: "POST",
		params: {
			id: id
		}
	})
}

export function save(form) {
	return http.request({
		url: service + "save",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function updateById(form) {
	return http.request({
		url: service + "updateById",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}