/**
 * @constant 路由缓存数量上限
 */
const ROUTE_CACHE_MAX_NUM = 12;

/**
 * @constant 用户状态
 */
const USER_STATUS = {
  normal: {
    label: "正常",
    color: "#18bc9c",
  },
  hidden: {
    label: "隐藏",
    color: "#d2d6de",
  },
};

/**
 * @constant 用户状态选项
 */
const USER_STATUS_OPTIONS = [
  { label: "正常", value: "normal" },
  { label: "隐藏", value: "hidden" },
];

/**
 * @constant 默认分页配置
 */
const BASE_PAGINATION = {
  pageSizes: [10, 15, 20, 25, 50],
  pageSize: 10,
  currPage: 1,
};

/**
 * @constant 管理员组别
 */
const AMDIN_GROUP_OPTINOS = [{ label: "商家组", value: "3" }];

/**
 * 会员/顾客组别
 */
const CUSTOMER_GRUOP_OPTIONS = [{ label: "默认组", value: 1 }];
const CUSTOMER_GROUP = {
  0: "-",
  1: "默认组",
};

/**
 * @constant 是或否
 */
const YES_OR_NO = {
  true: {
    label: "是",
    color: "#18bc9c",
  },
  false: {
    label: "否",
    color: "#2c3e50",
  },
};

/**
 * @constant 订单状态
 */
const ORDER_STATUS = {
  true: {
    label: "正常",
    color: "#18bc9c",
  },
  false: {
    label: "取消订单",
    color: "#2c3e50",
  },
};

/**
 * @constant 订单状态
 */
const ORDER_STATUS_OPTIONS =[
  {
    value: 1,
    label: "正常",
    color: "#18bc9c",
  },
  {
    value: 0,
    label: "取消订单",
    color: "#2c3e50",
  }
];

/**
 * @constant 订单类型
 */
const ORDER_TYPE = {
  1: {
    label: "普通订单",
    color: "#18bc9c",
  },
  2: {
    label: "员工订单",
    color: "#2c3e50",
  },
};

/**
 * @constant 订单付款类型
 */
const ORDER_PAY_TYPE = {
  3: {
    label: "微信支付",
  },
  4: {
    label: "支付宝付",
  },
  5: {
    label: "余额支付",
  },
};
/**
 * @constant 订单购买类型
 */
const ORDER_BUY_TYPE = {
  1: {
    label: "自助",
  },
  2: {
    label: "外卖",
  },
};


/**
 * @constant 广告图链接类型选项
 */
const ADVERT_LINK_TYPE = {
  "pages": "页面",
  "miniprogram": "小程序",
  "menu": "菜单",
  "content": "内容"
};

const ADVERT_LINK_TYPE_OPTIONS = [
  { label: "页面", value: "pages" },
  { label: "小程序", value: "miniprogram" },
  { label: "菜单", value: "menu" },
  { label: "内容", value: "content" },
];

/**
 * @constant 分类选项
 */
const CATEGORY_OPTIONS = [
  { label: "全部", value: "all" },
  { label: "产品", value: "product" },
];

/**
 * @constant 分类标志
 */
const CATEGORY_TAG_OPTIONS = [
  { label: "折扣", value: "discount" },
  { label: "新品", value: "new" },
];

const COUPON_USE_TYPE_OPTIONS = [
  { label: "通用", value: 0 },
  { label: "自取", value: 1 },
  { label: "外卖", value: 2 },
];

/**
 * @constant 优惠券使用类型
 */
const COUPON_USE_TYPE = {
  0: {
    label: "通用",
    color: "#2c3e50",
  },
  1: {
    label: "自取",
    color: "#18bc9c",
  },
  2: {
    label: "外卖",
    color: "#e74c3c",
  },
};

/**
 * @constant 系统配置选项
 */
const SYSTEM_CONFIG_OPTIONS = [
  { label: "基础配置", value: "basic" },
  { label: "字典配置", value: "dictionary" },
  { label: "微信配置", value: "wechat" },
  { label: "微信订阅信息", value: "subscribe_msg" },
  { label: "User Operation", value: "user_operation" },
];

const ADVERT_LOCATION_TYPE = {
  top: "顶部轮播图",
  popup: "中部弹出图",
  bottom: "底部推荐图",
};

/**
 * @constant 打印配置选项
 */
const PRINT_CONFIG_OPTIONS = [
  { label: "外卖", value: "外卖" },
  { label: "商超", value: "商超" },
  { label: "物流", value: "物流" }
];

/**
 * @constant 打印机品牌选项
 */
const PRINT_BRAND_OPTIONS = [
  { label: "中午", value: "zhongwu" },
  { label: "飞鹅", value: "feie" },
  { label: "易联云", value: "yilianyun" }
];

/**
 * @constant 打印机品牌选项
 */
const PRINT_BRAND = {
  'zhongwu': {
    label: "中午",
    color: "#2c3e50",
  },
  'feie': {
    label: "飞鹅",
    color: "#18bc9c",
  },
  'yilianyun': {
    label: "易联云",
    color: "#e74c3c",
  },
};

/**
 * @constant 打印机状态类型
 */
const PRINT_STATUS = {
  '0': {
    label: "离线",
    color: "#2c3e50",
  },
  '1': {
    label: "在线",
    color: "#18bc9c",
  },
  '2': {
    label: "缺纸",
    color: "#e74c3c",
  },
};

/**
 * @constant 打印机状态选项
 */
const PRINT_STATUS_OPTIONS = [
  { label: "离线", value: "0" },
  { label: "在线", value: "1" },
  { label: "缺纸", value: "2" }
];

/**
 * @constant 用户状态
 */
const EMPLOYEE_STATUS = {
  1: {
    label: "正常",
    color: "#18bc9c",
  },
  0: {
    label: "禁用",
    color: "#d2d6de",
  },
};

/**
 * @constant 用户状态选项
 */
const EMPLOYEE_STATUS_OPTIONS = [
  { label: "正常", value: 1 },
  { label: "禁用", value: 0 },
];

/**
 * @constant 门店类型
 */
const SHOP_TYPE = {
  1: {
    label: "普通门店",
    color: "#18bc9c",
  },
  2: {
    label: "0元畅饮店",
    color: "#d2d6de",
  },
};

/**
 * @constant 门店类型选项
 */
const SHOP_TYPE_OPTIONS = [
  { label: "普通门店", value: 1 },
  { label: "0元畅饮店", value: 2 },
];

export {
  ROUTE_CACHE_MAX_NUM,
  USER_STATUS,
  USER_STATUS_OPTIONS,
  BASE_PAGINATION,
  AMDIN_GROUP_OPTINOS,
  CUSTOMER_GROUP,
  CUSTOMER_GRUOP_OPTIONS,
  YES_OR_NO,
  ORDER_STATUS,
  ORDER_STATUS_OPTIONS,
  ORDER_TYPE,
  ORDER_PAY_TYPE,
  ORDER_BUY_TYPE,
  ADVERT_LINK_TYPE,
  ADVERT_LINK_TYPE_OPTIONS,
  CATEGORY_OPTIONS,
  CATEGORY_TAG_OPTIONS,
  COUPON_USE_TYPE_OPTIONS,
  COUPON_USE_TYPE,
  SYSTEM_CONFIG_OPTIONS,
  ADVERT_LOCATION_TYPE,
  PRINT_CONFIG_OPTIONS,
  PRINT_BRAND_OPTIONS,
  PRINT_BRAND,
  PRINT_STATUS,
  PRINT_STATUS_OPTIONS,
  EMPLOYEE_STATUS,
  EMPLOYEE_STATUS_OPTIONS,
  SHOP_TYPE,
  SHOP_TYPE_OPTIONS,
};
