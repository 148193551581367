<template>
  <page-list noMarginFlag>
    <template #header>
      <el-button type="info" class="fa fa-refresh" @click="getList"></el-button>
      <el-button
        v-if="mode == 'view'" 
        class=""
        type="primary"
        @click="
          editMode = 'add';
          dialogVisible = true;
        "
        >添加</el-button
      >
    </template>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      v-loading="loading"
      element-loading-background="transparent"
      @sort-change="sortChangeHandle"
      @row-dblclick="editHandle"
    >
      <el-table-column
        prop="id"
        label="ID"
        min-width="40px"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="shopimages"
        label="门店头像"
        header-align="center"
        align="center"
        min-width="80"
      >
        <template v-slot="scope">
          <el-image
            v-if="scope.row.shopimages"
            style="width: 30px; height: 30px"
            :src="scope.row.shopimages.split(',')[0]"
            :preview-src-list="scope.row.shopimages.split(',')"
            preview-teleported
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="店铺名称"
        header-align="center"
        align="center"
        min-width="150px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        label="店铺电话"
        header-align="center"
        align="center"
        min-width="120px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="addressMap"
        label="地图定位地址"
        header-align="center"
        align="center"
        min-width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="address"
        label="详细地址"
        header-align="center"
        align="center"
        min-width="200px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="type"
        label="门店类型"
        header-align="center"
        align="center"
        min-width="120px"
      >
        <!-- <template v-slot="scope" >
          <span  v-if="scope.row.type">{{ " " + SHOP_TYPE[scope.row.type].label }}</span
          >
        </template> -->
         <template v-slot="scope">
           <view v-if="scope.row.type">
            <el-tag v-if="scope.row.type == '1'" type="info" effect="dark">{{ SHOP_TYPE[scope.row.type].label}}</el-tag>
            <el-tag v-if="scope.row.type == '2'" type="danger" effect="dark">{{ SHOP_TYPE[scope.row.type].label}}</el-tag>
           </view>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="freeRange"
        label="0元畅饮范围(米)"
        header-align="center"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <el-table-column
        prop="visibleRange"
        label="可见范围(千米)"
        header-align="center"
        align="center"
        min-width="80px"
      >
      </el-table-column> -->
      <el-table-column
        prop="qrcodeUrl"
        label="门店二维码"
        header-align="center"
        align="center"
        min-width="100"
      >
        <template v-slot="scope">
          <el-image
            v-if="scope.row.qrcodeUrl"
            style="width: 30px; height: 30px"
            :src="scope.row.qrcodeUrl.split(',')[0]"
            :preview-src-list="scope.row.qrcodeUrl.split(',')"
            preview-teleported
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="starttime"
        label="营业开始时间"
        header-align="center"
        align="center"
        :formatter="(row) => $moment.unix(row.starttime).format('HH:mm')"
        min-width="120px"
      >
      </el-table-column>
      <el-table-column
        prop="endtime"
        label="营业结束时间"
        header-align="center"
        align="center"
        :formatter="(row) => $moment.unix(row.endtime).format('HH:mm')"
        min-width="120px"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="createtime"
        label="创建时间"
        :formatter="
          (row) => $moment.unix(row.createtime).format('yyyy-MM-DD HH:mm:ss')
        "
        min-width="150px"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="updatetime"
        label="更新时间"
        :formatter="
          (row) => $moment.unix(row.updatetime).format('yyyy-MM-DD HH:mm:ss')
        "
        min-width="150px"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="status"
        label="是否营业"
        fixed="right"
      >
        <template v-slot="scope">
          <el-switch
            v-model="scope.row.status"
            active-color="#18bc9c"
            inactive-color="#d2d6de"
            :active-value="1"
            :inactive-value="0"
            @change="statusChangeHandle(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        v-if="mode == 'view'" 
        label="操作"
        header-align="center"
        align="center"
        fixed="right"
      >
        <template v-slot="scope">
          <el-button
            class="icon-btn fa fa-pencil"
            type="primary"
            @click="editHandle(scope.row)"
          >
            <!-- 编辑 -->
          </el-button>
          <el-button
            class="icon-btn fa fa-trash"
            type="danger"
            @click="delHandle(scope.row)"
          >
            <!-- 删除 -->
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-model="paginationData"
      :total="total"
      @change="getList"
    ></pagination>

    <EditDialog
      v-model="dialogVisible"
      @refresh="getList"
      :editData="editData"
      :mode="editMode"
    ></EditDialog>
  </page-list>
</template>

<script>
import { SHOP_TYPE,SHOP_TYPE_OPTIONS } from "@/helpers/constants";
import EditDialog from "./editDialog";
import { getShopList } from "@/apis/auth";
import { deleteById,listByPage,toggleById } from "@/apis/unidrink/shop";
import { cloneDeep } from "lodash";
import { msgErr, msgSuc } from "@/helpers/message";

export default {
  components: { EditDialog },
  props: {
    mode: {
      type: String,
      default: "view" // view-页面；component-组件
    }
  },
  data() {
    return {
      SHOP_TYPE:SHOP_TYPE,
      tableData: [],
      paginationData: {},
      initForm: {
        sort: "id",
        order: "desc",
      },
      form: {},
      total: 0,
      loading: false,
      dialogVisible: false,
      editMode: "add",
      editData: {},
    };
  },
  created() {},
  mounted() {
    if (this.mode == "component") {
      this.getList();
    }
  },
  activated() {
    this.form = cloneDeep(this.initForm);
    this.form.adminId = this.$store.getters.userInfo.id
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      listByPage({ ...this.form, ...this.paginationData })
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    dateFormatter(row) {
      return row.createtime
        ? this.$moment.unix(row.createtime).format("yyyy-MM-DD HH:mm:ss")
        : "无";
    },

    sortChangeHandle({ prop, order }) {
      if (prop && order) {
        this.form = {
          sort: prop,
          order: order === "descending" ? "desc" : "asc",
        };
      } else {
        this.form = cloneDeep(this.initForm);
      }
      this.getList();
    },

    delHandle(row) {
      this.$confirm("确定删除此项？", "温馨提示").then(() => {
        deleteById(row.id).then(({ code, result, message }) => {
          if (code === "0000") {
            msgSuc("操作成功！");
            this.getList();
          } else {
            msgErr(message);
          }
        });
      });
    },

    editHandle(row) {
      if (this.mode == "component") {
        this.$emit("choose", row);
      } else {
        this.editMode = "edit";
        this.dialogVisible = true;
        this.editData = row;
      }
    },
    statusChangeHandle(row) {
      toggleById(row, "status")
        .then(({ code, message  }) => {
          if (code === "0000") {
            msgSuc("操作成功！");
            this.getList();
          } else {
            msgErr(message);
          }
        })
    },
  },
};
</script>

<style lang="scss" scoped></style>
