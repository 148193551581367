import jsCookie from "js-cookie";

const cookieNames = {
  jwt: "TOKEN", // PHPSESSID
  PHPSESSID: "PHPSESSID",
};

const storageNames = {
  userInfo: "USERINFO",
  cos: "COS",
};

export function setCookie(name, value, data) {
  return jsCookie.set(name, value, data);
}

export function getCookie(name) {
  return jsCookie.get(name);
}

export function clearCookie() {
  Object.keys(cookieNames).forEach((key) => {
    jsCookie.remove(cookieNames[key]);
  });
}

// cookies
export function setJwt(jwt) {
  jsCookie.set(cookieNames.jwt, jwt);
  jsCookie.set("think_var", "zh-cn");
}
export function getJwt() {
  return jsCookie.get(cookieNames.jwt);
}

// localstorage
export function getUserInfo() {
  const userInfo = localStorage.getItem(storageNames.userInfo);
  if (userInfo) {
    return JSON.parse(userInfo);
  } else {
    return {};
  }
}
export function setUserInfo(userInfo) {
  localStorage.setItem(storageNames.userInfo, JSON.stringify(userInfo));
}

export function setCos(cos, expires) {
  localStorage.setItem(storageNames.cos, JSON.stringify(cos));
}
export function getCos() {
  const cosInfoStr = localStorage.getItem(storageNames.cos);
  if (cosInfoStr) {
    const cos = JSON.parse(cosInfoStr);
    if (cos["expiredTime"] * 1000 - Date.now() > 60) {
      // 至少还有60s有效时间才返回，否则需要重新获取权限
      return cos;
    }
  }
  return null;
}
