import { ROUTE_CACHE_MAX_NUM } from "@/helpers/constants";
import { remove } from "lodash";

export default {
  namespaced: true,
  state: () => ({
    menuCollapse: false, // 菜单是否折叠
    cacheList: [],
  }),
  getters: {
    menuCollapse: (state) => state.menuCollapse,
    cacheList: (state) => state.cacheList,
  },
  mutations: {
    setMenuCollapse(state, payload) {
      state.menuCollapse = payload;
    },
    setCacheList(state, payload) {
      state.cacheList = payload;
    },
  },
  actions: {
    toggleMenuCollapse({ commit, getters }) {
      commit("setMenuCollapse", !getters.menuCollapse);
    },
    addRouteCache({ commit, getters }, payload) {
      const caches = getters.cacheList;
      if (!caches.find((route) => route.path === payload.path)) {
        caches.push(payload);
      }
      commit("setCacheList", caches);
    },
    removeRouteCache({ commit, getters }, payload) {
      const caches = getters.cacheList;
      if (caches.find((route) => route.path === payload.path)) {
        remove(caches, (route) => route.path === payload.path);
      }
      commit("setCacheList", caches);
    },
  },
};
