<template>
  <div style="width: 100%">
    <div>
      <el-input v-model="showInfo[showKey]" readonly>

        <template  #suffix>
          
          <el-tooltip content="清空" placement="top"  v-if="clearable" >
            <div class="fa fa-times-circle clear" @click="clear" ></div>
          </el-tooltip>
        </template>
        <template   #append>
          
          <el-button icon="ele-icon-search" @click="dialogVisible = true" :disabled="disabled" 
            >选择</el-button
          >
        </template>
      </el-input>
    </div>

    <el-dialog
      title="双击选择"
      :model-value="dialogVisible"
      @close="dialogVisible = false"
      :close-on-click-modal="false"
      :fullscreen="true"
      :append-to-body="true"
    >
      <drink-channel
        v-if="mode == 'drink-channel'"
        mode="component"
        @choose="chooseConfirm"
      ></drink-channel>
      <drink-shopbox
        v-if="mode == 'drink-shopbox'"
        mode="component"
        @choose="chooseConfirm"
      ></drink-shopbox>
      <drink-shop
        v-if="mode == 'drink-shop'"
        mode="component"
        @choose="chooseConfirm"
      ></drink-shop>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  props: {
    mode: {
      type: String,
      default: "boss", // boss-boss；role-角色
    },
    data: {
      type: Object,
      default: () => {},
    },
    clearable:{
      type: Boolean,
      default:false
    },
    disabled:{
      type: Boolean,
      default:false
    },
    showKey:{
      type: String,
      default:"name"
    }
  },
  data() {
    return {
      dialogVisible: false,
      showInfo: this.data,
    };
  },
  emits: ["choose"],
  created() {},
  watch: {
    data(val) {
      if (val) {
        this.showInfo = cloneDeep(val);
      }
    },
  },
  methods: {
    /**
     * 确认选中
     * @param {Object} item
     */
    chooseConfirm(item) {
      this.$emit("choose", item);
      this.dialogVisible = false;
    },

    close() {},
    clear(){
      this.$emit("choose", {});
    }
  },
};
</script>

<style lang="scss" scoped>
.clear{
  margin:  auto;
  padding: 0 15px;
  cursor: pointer;
}
</style>
