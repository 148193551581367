import http from "@/helpers/http";
import { pack } from "@/helpers/utils";
import { msgErr } from "@/helpers/message";
const service = "faChannel/";

export function listByPage(form) {
	return http.request({
		url: service + "listByPage",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function deleteById(id) {
	return http.request({
		url: service + "deleteById",
		method: "POST",
		params: {
			id: id
		}
	})
}

export function save(form) {
	return http.request({
		url: service + "save",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function updateById(form) {
	return http.request({
		url: service + "updateById",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function toggleById(form, key) {
	return http.request({
		url: service + "updateById",
		method: "POST",
		data: {
			body: {
				id: form.id,
				[key]: form[key],
			},
		},
	});
}


export async function getChannelOptions(channelOptions = [], form = {}, all = true) {
	const page = 1;
	const limit = 10;
	channelOptions.splice(0, channelOptions.length);
	if (all) {
	  channelOptions.push({ name: "全部", id: 0 });
	}
	const fn = async (currPage, pageSize) => {
	  await listByPage({
		currPage:currPage,
		pageSize:pageSize,
		...form
	  }).then(
		({ code, result, message }) => {
		  if (code !== "0000") {
			msgErr(message);
			return;
		  }
		  const { list, total } = result;
		  channelOptions.push(...list);
		  if (currPage * pageSize < total) {
			fn(currPage + 1, pageSize);
		  }
		}
	  );
	};
	await fn(page, limit);
  }