<template>
  <div style="width: 100%">
    <div>
      <div class="input">

        <el-tag v-for="(showInfo,index) in showList" :key="showInfo.id" closable class="tag" @close="closeHandle(index)">
          {{showInfo[showKey]}}
        </el-tag>
        <el-button class="button"  @click="dialogVisible = true" :disabled="disabled">选择</el-button>
      </div>
    </div>

    <el-dialog title="双击选择" :model-value="dialogVisible" @close="dialogVisible = false" :close-on-click-modal="false" :fullscreen="true" :append-to-body="true">
      <tuan-category v-if="mode == 'category'" mode="component" @choose="chooseConfirm"></tuan-category>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  props: {
    mode: {
      type: String,
      default: "boss" // boss-boss；role-角色
    },
    data: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showKey: {
      type: String,
      default: "name"
    }
  },
  data() {
    return {
      dialogVisible: false,
      showList: this.data
    };
  },
  emits: ["change"],
  created() {},
  watch: {
    data(val) {
      if (val) {
        this.showList = cloneDeep(val);
      }
    }
  },
  methods: {
    /**
     * 确认选中
     * @param {Object} item
     */
    chooseConfirm(item) {
      this.dialogVisible = false;
      let flag= this.showList.some(showInfo=>{
        return showInfo.id==item.id
      });
      if(flag){
        return;
      }
      this.showList.push(item);
      this.$emit("change", this.showList);
      
    },

    closeHandle(index){

          this.showList.splice(index,1);
          this.$emit("change", this.showList);
          return;
 
    },

    close() {}
  }
};
</script>

<style lang="scss" scoped>
.input {
  flex: 1;
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  border-color: #dbdbdb;
  border-style: solid;
  height:32px;
  border-width: 1px;
  border-radius: 3px;
  background-color: #fff;
}
.tag{
  margin-left:2px;
}
.button{
  position: absolute;
  right:0;
}
</style>
