import {
  setJwt,
  getJwt,
  clearCookie,
  setUserInfo,
  getUserInfo,
  setCookie,
} from "@/helpers/storage";
import { globalRoutes, pageRoutes } from "@/routers";
import { msgErr } from "@/helpers/message";
import { cloneDeep, remove } from "lodash";
import router from "@/routers";
import { getPermission } from "@/apis/common";

const initState = {
  userInfo: null, // 用户信息
  jwt: getJwt(), // jwt
  menus: [], // 用户权限菜单
  hasPermission: false, // 是否获得授权
  permissionList: [], // 权限列表
  permissionRoutes: [], // 权限路由表
};

export default {
  state: () => ({
    ...initState,
  }),
  getters: {
    jwt: (state) => state.jwt,
    menus: (state) => {
      return state.menus;
    },
    userInfo: (state) => state.userInfo || getUserInfo(),
    hasPermission: (state) => state.hasPermission,
    permissionList: (state) => state.permissionList,
    permissionRoutes: (state) => state.permissionRoutes,
  },
  mutations: {
    setJwt(state, paylod) {
      setJwt(paylod);
      state.jwt = paylod;
    },
    setPermissionList(state, payload) {
      state.permissionList = payload;
    },
    setPermissionRoutes(state, payload) {
      state.permissionRoutes = payload;
    },
    setMenus(state, payload) {
      state.menus = payload;
      state.hasPermission = true;
    },
    setUser(state, payload) {
      state.userInfo = payload;
    },
  },
  actions: {
    /**
     * 登录成功
     */
    loginSuccess({ commit }, payload) {
      commit("setJwt", payload.token);
      // setCookie("PHPSESSID", "2p9s1uq18ag2mvoccd6mc92e8k");
      setUserInfo(payload);
      commit("setUser", payload);
    },

    /**
     * 获取权限
     */
    getPermission({ commit, dispatch, getters }) {
      const userId = getters.userInfo;
      return getPermission(userId.id)
        .then(async (data) => {
          if (data.code === "0000" && Array.isArray(data.result.list)) {
            commit("setPermissionList", data.result.list);
            await dispatch("generateMenus");
          } else {
            msgErr(data.message || "获取权限失败");
            // 清空缓存并跳转登录页
            throw Error();
          }
        })
        .catch(() => {
          dispatch("logout");
        });
    },

    /**
     * 生成菜单
     */
    generateMenus({ getters, commit }) {
      // 路由筛选
      // 一级路由筛选
      const permissionRoutes = cloneDeep(pageRoutes).filter((firstRoute) => {
        return !!getters.permissionList.find(
          (permission) => permission.id === firstRoute.meta.permissionId
        );
      });
      permissionRoutes.forEach((firstRoute) => {
        if (Array.isArray(firstRoute.children) && firstRoute.children.length) {
          // 二级路由筛选
          firstRoute.children = firstRoute.children.filter((secondRoute) => {
            return !!getters.permissionList.find(
              (permission) => permission.id === secondRoute.meta.permissionId
            );
          });
        }
      });
      // 动态添加权限路由
      permissionRoutes.forEach((route) => {
        router.addRoute(route);
      });
      commit("setPermissionRoutes", permissionRoutes);

      // 菜单筛选
      let cloneMenus = cloneDeep(permissionRoutes);
      const removeHidden = (menus) => {
        remove(menus, (menu) => menu.hidden);
        const hasSubMenus = menus.filter(
          (menu) => Array.isArray(menu.children) && menu.children.length
        );
        hasSubMenus.forEach((menu) => {
          removeHidden(menu.children);
        });
      };
      removeHidden(cloneMenus);

      // 无子菜单权限，则父菜单直接移除
      cloneMenus = cloneMenus.filter((item) => item.children.length > 0);

      commit("setMenus", cloneMenus);
    },

    logout({ commit, state }) {
      state = { ...initState };
      commit("setJwt", null);
      commit("setJwt", null);
      clearCookie();

      // 需要重定向，清空所有缓存
      location.href = "/login";
    },
  },
};
