import http from "@/helpers/http";
import qs from "qs";

export function getAdminList(form) {
  return http.request({
    url: "/SYTdknLasJ.php/auth/admin/index",
    method: "get",
    params: {
      _: Date.now(),
      ...form,
    },
  });
}

export function deleteAdmin(id) {
  return http.request({
    url: "/SYTdknLasJ.php/auth/admin/del",
    method: "POST",
    params: {
      action: "del",
      ids: id,
    },
  });
}

export function addAdmin(form, token) {
  return http.request({
    url: "/SYTdknLasJ.php/auth/admin/add",
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      "group[]": form.groups,
      "row[username]": form.username,
      "row[email]": form.email,
      "row[nickname]": form.nickname,
      "row[password]": form.password,
      "row[status]": form.status,
    }),
    params: {
      dialog: 1,
      __token__: token,
    },
  });
}

export function editAdmin(form, token) {
  return http.request({
    url: `/SYTdknLasJ.php/auth/admin/edit/ids/${form.id}`,
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      "group[]": form.groups,
      "row[username]": form.username,
      "row[email]": form.email,
      "row[nickname]": form.nickname,
      "row[password]": form.password,
      "row[loginfailure]": form.loginfailure,
      "row[status]": form.status,
    }),
    params: {
      dialog: 1,
      __token__: token,
    },
  });
}

export function getShopList(form) {
  return http.request({
    url: "/SYTdknLasJ.php/unidrink/shop/index",
    method: "get",
    params: {
      _: Date.now(),
      ...form,
    },
  });
}

export function toggleShopStatus(id, status) {
  return http.request({
    url: "/SYTdknLasJ.php/unidrink/shop/multi",
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      ids: id,
      params: `status=${status}`,
    }),
  });
}

export function deleteShop(id) {
  return http.request({
    url: "/SYTdknLasJ.php/unidrink/shop/del",
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      action: "del",
      ids: id,
    }),
  });
}

export function addShop(form) {
  return http.request({
    url: "/SYTdknLasJ.php/unidrink/shop/add?dialog=1",
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      "row[name]": form.name,
      "row[mobile]": form.mobile,
      "row[shopimages]": form.shopimages,
      "row[images]": form.images,
      "row[starttime]": form.starttime,
      "row[endtime]": form.endtime,
      "row[address_map]": form.address_map,
      "row[lng]": form.lng,
      "row[lat]": form.lat,
      "row[province]": form.regions[0],
      "row[city]": form.regions[1],
      "row[area]": form.regions[2],
      "row[address]": form.address,
      "row[notice]": form.notice,
      "row[admin_id]_text": form.endtime,
      "row[admin_id]": form.admin_ids.join(","),
      "row[uniprint_id]_text": form.uniprint_id,
      "row[uniprint_id]": form.uniprint_id,
      "row[imgprint_id]_text": form.imgprint_id,
      "row[imgprint_id]": form.imgprint_id,
      "row[status]": form.status,
    }),
  });
}

export function editShop(form) {
  const starttime = `${form.starttime.getHours()}:${form.starttime.getMinutes()}`;
  const endtime = `${form.endtime.getHours()}:${form.endtime.getMinutes()}`;

  return http.request({
    url: `/SYTdknLasJ.php/unidrink/shop/edit/ids/${form.id}?dialog=1`,
    method: "post",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      "row[name]": form.name,
      "row[mobile]": form.mobile,
      "row[shopimages]": form.shopimages,
      "row[images]": form.images,
      "row[starttime]": starttime,
      "row[endtime]": endtime,
      "row[address_map]": form.address_map,
      "row[lng]": form.lng,
      "row[lat]": form.lat,
      "row[province]": form.regions[0],
      "row[city]": form.regions[1],
      "row[area]": form.regions[2],
      "row[address]": form.address,
      "row[notice]": form.notice,
      "row[admin_id]_text": form.endtime,
      "row[admin_id]": form.admin_ids.join(","),
      "row[uniprint_id]_text": form.uniprint_id,
      "row[uniprint_id]": form.uniprint_id,
      "row[imgprint_id]_text": form.imgprint_id,
      "row[imgprint_id]": form.imgprint_id,
      "row[status]": form.status,
    }),
  });
}

export function getAdminOptions(page, limit) {
  return http.request({
    url: "/SYTdknLasJ.php/auth/admin/index",
    method: "post",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      "q_word[]": "",
      pageNumber: page,
      pageSize: limit,
      andOr: "OR",
      // "orderBy[0][]": "nickname",
      searchTable: "tbl",
      showField: "nickname",
      keyField: "id",
      "searchField[]": "nickname",
      nickname: "",
    }),
  });
}

export function getPrintOptions(page, limit) {
  return http.request({
    url: "/SYTdknLasJ.php/uniprint/printer/index",
    method: "post",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      "q_word[]": "",
      pageNumber: page,
      pageSize: limit,
      andOr: "OR",
      // "orderBy[0][]": "name",
      searchTable: "tbl",
      showField: "name",
      keyField: "id",
      "searchField[]": "name",
      nickname: "",
    }),
  });
}

export function getShopOptions(shopOptions) {
  const page = 1;
  const limit = 10;
  const fn = (page, limit) => {
    getShopList(page, limit).then(({ rows, total }) => {
      shopOptions.push(...rows);
      if (page * limit < total) {
        fn(page + 1, limit);
      }
    });
  };
  fn(page, limit);
}
