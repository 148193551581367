import { ElMessage } from "element-plus";

export function msgErr(text) {
  ElMessage.error({
    message: text,
  });
}

export function msgSuc(text) {
  ElMessage.success({
    message: text,
  });
}
