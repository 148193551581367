<template>
  <div>
    <panel
      :type="activeTab"
      @add="addHandle"
      @edit="editHandle"
      :shopOptions="shopOptions"
      ref="panelRefs"
    ></panel>
    <EditDialog
      v-model="dialogVisible"
      @refresh="refreshHandle"
      :editData="editData"
      :mode="editMode"
    ></EditDialog>
  </div>
</template>

<script>
import { getShopOptions } from "@/apis/auth";
import EditDialog from "./editDialog";
import panel from "./panel";

export default {
  components: { panel, EditDialog },
  props: {
    mode: {
      type: String,
      default: "view", // view-页面；component-组件
    },
  },
  data() {
    return {
      dialogVisible: false,
      editMode: "add",
      editData: {},
      shopOptions: [],
    };
  },
  created() {
    getShopOptions(this.shopOptions);
  },
  activated() {
    this.refreshHandle();
  },
  methods: {
    setItemRef(el, key) {
      this.panelRefs[key] = el;
    },
    tabChangeHandle({ props }) {
      const { name } = props;
      this.activeTab = name;
      this.$nextTick(() => {
        this.refreshHandle();
      });
    },
    addHandle() {
      this.editMode = "add";
      this.dialogVisible = true;
    },
    editHandle(row) {
      console.log("editHandle", row);
      this.editMode = "edit";
      this.editData = row;
      this.dialogVisible = true;
    },
    refreshHandle() {
      this.$refs.panelRefs.getList();
    },
  },
};
</script>

<style lang="scss" scoped></style>
