import http from "@/helpers/http";
import qs from "qs";
import { packFormUrl } from "@/helpers/utils";
import { pack } from "@/helpers/utils";
const service = "faUnidrinkShop/";



export function listByPage(form) {
	return http.request({
		url: service + "listByPage",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function deleteById(id) {
	return http.request({
		url: service + "deleteById",
		method: "POST",
		params: {
			id: id
		}
	})
}

export function save(form) {
	return http.request({
		url: service + "save",
		method: "POST",
		data: pack(form)
	})
}

export function updateById(form) {
	return http.request({
		url: service + "updateById",
		method: "POST",
		data: pack(form)
	})
}



export function toggleById(form, key) {
	return http.request({
		url: service + "updateById",
		method: "POST",
		data: {
			body: {
				id: form.id,
				[key]: form[key],
			},
		},
	});
}