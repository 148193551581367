<template>
  <div class="page-list_container" >
    <div class="page-list_header" :class="{nomargin:noMarginFlag}">
      <slot name="header"></slot>
    </div>
    <div class="page-list_main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    noMarginFlag: {
      type: Boolean,
      default: false, // add-新增；edit-编辑
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.page-list_container {
  margin: 16px;
  display: flex;
  flex-direction: column;

  .page-list_header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    background: #ffffff;
    margin-bottom: 16px;
    padding: 16px 16px 0 16px;
    border-radius: 4px;
  }
  .nomargin {
    margin-bottom: 0;
  }
  .page-list_main {
    background: #ffffff;
    padding: 16px;
    border-radius: 4px;
  }
  .page-list_footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
  }
}
</style>
