import COS from "cos-js-sdk-v5";
import { getCosAuth } from "@/apis/common";
import { msgErr } from "@/helpers/message";
import { setCos, getCos } from "@/helpers/storage";
import { MD5 } from "crypto-js";
import { v4 as uuidv4 } from "uuid";

class TCOS {
  cos = null;

  isExpired = () => {
    return !getCos();
  };

  getAuth = () => {
    return new Promise((resolve, reject) => {
      getCosAuth().then(({ code, message, result }) => {
        if (code !== "0000") {
          msgErr(message);
          reject();
        }
        setCos(result);
        resolve(result);
      });
    });
  };

  getInstance = async () => {
    if (!this.cos) {
      if (this.isExpired()) {
        await this.getAuth();
      }
      const authInfo = getCos();
      this.cos = new COS({
        getAuthorization: (options, callback) => {
          callback({
            TmpSecretId: authInfo.credentials.tmpSecretId,
            TmpSecretKey: authInfo.credentials.tmpSecretKey,
            SecurityToken: authInfo.credentials.sessionToken,
            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            StartTime: authInfo.startTime, // 时间戳，单位秒，如：1580000000
            ExpiredTime: authInfo.expiredTime, // 时间戳，单位秒，如：1580000000
          });
        },
      });
      return Promise.resolve(this.cos);
    } else {
      return Promise.resolve(this.cos);
    }
  };

  put = (file) => {
    return new Promise((resolve, reject) => {
      const suffix = file.name.substring(file.name.lastIndexOf("."));
      this.getInstance().then(
        (cos) => {
          cos.putObject(
            {
              Bucket: "unidrink-1256645587" /* 必须 */,
              Region: "ap-shanghai" /* 存储桶所在地域，必须字段 */,
              Key: MD5(file.name + uuidv4()) + suffix /* 必须 */,
              StorageClass: "STANDARD",
              Body: file, // 上传文件对象
            },
            (err, data) => {
              if (data && data.statusCode === 200) {
                resolve(data);
              } else {
                reject(err.message);
              }
            }
          );
        },
        (err) => {
          reject(err);
        }
      );
    });
  };
}

export default new TCOS();
