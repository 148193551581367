import router from "@/routers";
import store from "@/stores";

router.beforeEach(async (to, from) => {
  // console.log(to, from, router);

  // if (store.getters.jwt) {
    if (to.path === "/login") {
      return true;
    } else {
      if (!store.getters.hasPermission) {
        // 获取权限
        await store.dispatch("getPermission");

        if (from.path === "/login" && store.getters.permissionRoutes.length) {
          //登录成功自动跳转第一个菜单/权限路由
          const first = store.getters.permissionRoutes.find((first) => {
            return Array.isArray(first.children) && first.children.length;
          });
          return {
            path: first.path + "/" + first.children[0].path,
            replace: true,
          };
        }

        return { path: to.path, replace: true };
      } else {
        if (to.meta.keepAlive) {
          store.dispatch("app/addRouteCache", to);
        }
        return true;
      }
    }
  // } else {
  //   if (to.path === "/login") {
  //     return true;
  //   } else {
  //     return { path: "/login", replace: true };
  //   }
  // }
});
