<template>
  <div>
    <el-dialog
      title="地址选择"
      :model-value="modelValue"
      width="800px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div class="search-ctan">
        <el-select
          v-model="keyword"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键字搜索地址"
          :remote-method="remoteMethod"
          :loading="loading"
          id="keyword_input"
        >
          <el-option
            v-for="item in markOptions"
            :key="item.point"
            :label="item.label"
            :value="item"
          />
        </el-select>
        <el-button style="margin-left: 6px" type="primary"
          ><i class="fa fa-search"></i
        ></el-button>
      </div>
      <div id="map_container" class="map-container"></div>
      <template #footer>
        <div>
          <el-button @click="cancelHandle">取消</el-button>
          <el-button
            type="primary"
            @click="submitHandle"
            :disabled="!enableSubmit"
            >确 定</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      keyword: "",
      ac: null,
      markOptions: [],
      loading: false,
    };
  },
  emits: ["update:modelValue", "selected"],
  watch: {
    modelValue: {
      handler(val) {
        if (val && !this.map) {
          this.$nextTick(() => {
            this.initMap();
          });
        }
        if (val) {
          this.keyword = "";
        }
      },
      immediate: true,
    },
  },
  computed: {
    enableSubmit() {
      return !!this.keyword;
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },
    cancelHandle() {
      this.$emit("update:modelValue", false);
    },
    submitHandle() {
      let point = this.convert2TecentMap(this.keyword.point.lng,this.keyword.point.lat);
      this.keyword.point.lng = point.lng;
      this.keyword.point.lat = point.lat;
      this.$emit("selected", this.keyword);
      this.$emit("update:modelValue", false);
    },

    initMap() {
      this.map = new BMapGL.Map("map_container");
      let point = new BMapGL.Point(116.404, 39.915);

      // const geolocation = new BMapGL.Geolocation();
      // geolocation.getCurrentPosition(function (r) {
      //   if (this.getStatus() == BMapGL.BMAP_STATUS_SUCCESS) {
      //     alert("您的位置：" + r.point.lng + "," + r.point.lat);
      //     point = r.point;
      //   } else {
      //     alert("failed" + this.getStatus());
      //   }
      // });

      this.map.centerAndZoom(point, 15);
      this.map.enableScrollWheelZoom(true);
      const cityCtrl = new BMapGL.CityListControl(); // 添加城市列表控件
      this.map.addControl(cityCtrl);

      this.ac = new BMapGL.Autocomplete({
        input: "keyword_input",
        location: this.map,
      }); //建立一个自动完成的对象
    },

    remoteMethod(args) {
      if (!args) {
        return;
      }
      this.loading = true;
      const local = new BMapGL.LocalSearch(this.map, {
        //智能搜索
        onSearchComplete: this.searchComplete,
      });
      local.search(args);
    },

    searchComplete(res) {
      this.loading = false;
      console.log("searchComplete", res);
      const { _pois } = res;

      this.markOptions = _pois.map((item) => {
        return {
          ...item,
          label: `${item.title}(${item.address})`,
        };
      });
    },
    convert2TecentMap(lng, lat) {
      /* eslint-disable */
      if (lng == '' && lat == '') {
        return {
          lng: '',
          lat: ''
        }
      }
      var x_pi = 3.14159265358979324 * 3000.0 / 180.0
      var x = lng - 0.0065
      var y = lat - 0.006
      var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi)
      var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi)
      var qqlng = z * Math.cos(theta)
      var qqlat = z * Math.sin(theta)
      return {
        lng: qqlng,
        lat: qqlat
      }
      /* eslint-enable */
    }
  },
};
</script>

<style lang="scss" scoped>
.search-ctan {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-items: center;
  margin-inline: auto;
  margin-bottom: 12px;
}
.map-container {
  width: 100%;
  height: 640px;
}
</style>
