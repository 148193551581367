import pageList from "./pageList/index.vue";
import uniTable from "./uniTable/index.vue";
import uniTableColumn from "./uniTableColumn/index.vue";
import pagination from "./pagination";
import uploads from "./uploads";
import drinkChannel from "../views/unidrink/channel/index.vue"
import drinkShopbox from "../views/unidrink/shopbox/index.vue"
import drinkShop from "../views/auth/shop/index.vue"
import picker from "./picker/index.vue"
import pickers from "./pickers/index.vue"

export default {
  install(Vue) {
    Vue.component("page-list", pageList);
    Vue.component("uni-table", uniTable);
    Vue.component("uni-table-column", uniTableColumn);
    Vue.component("pagination", pagination);
    Vue.component("uploads", uploads);
    Vue.component("drink-channel", drinkChannel)
    Vue.component("drink-shopbox", drinkShopbox)
    Vue.component("drink-shop", drinkShop)
    Vue.component("picker", picker)
		Vue.component("pickers", pickers)
  },
};
