import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { MD5 } from "crypto-js";
import store from "@/stores";
import { msgErr } from "@/helpers/message";
import qs from "qs";

const http = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? "/" : "/liconow",
  timeout: 10000,
});

http.defaults.proofing = true; // 开启防篡改
http.defaults.isform = false; // 是否form-encoded方式

http.interceptors.request.use((config) => {
  const random = uuidv4();
  config.headers["X-Requested-With"] = "XMLHttpRequest";
  config.headers["K-RandomNumber"] = uuidv4();
  config.headers["K-Token"] = store.getters.jwt;
  config.headers["K-VerifyTime"] = Date.now();
  config.headers["K-VerifyData"] = MD5(random + JSON.stringify(config.data));

  return config;
});
http.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.error(error);
    msgErr("连接服务器失败，请稍后再试");
  }
);

export default http;
