<template>
  <page-list class="page-list__spec">
    <template #header>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        inline="true"
      >
        <el-form-item label="所属门店：" prop="shopId">
          <el-select
            v-model="form.shopId"
            placeholder="请选择"
            :disabled="mode === 'edit'"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in shopOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addHandle()">添 加</el-button>
        </el-form-item>
      </el-form>
    </template>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      v-loading="loading"
      element-loading-background="transparent"
      @row-dblclick="editHandle"
      row-key="id"
    >
      <el-table-column
        prop="id"
        label="ID"
        min-width="60"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="渠道名称"
        header-align="center"
        align="center"
        min-width="120"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="shopInfo.name"
        label="所属门店"
        header-align="center"
        align="center"
        min-width="120"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="qrcodeUrl"
        label="渠道二维码"
        min-width="120"
        header-align="center"
        align="center"
      >
        <template v-slot="scope">
          <el-image
            v-if="scope.row.qrcodeUrl"
            style="width: 30px; height: 30px"
            :src="scope.row.qrcodeUrl.split(',')[0]"
            :preview-src-list="scope.row.qrcodeUrl.split(',')"
            preview-teleported
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        header-align="center"
        align="center"
        min-width="120"
        show-overflow-tooltip
      ></el-table-column>
      <!-- <el-table-column
        prop="status"
        label="状态"
        header-align="center"
        align="center"
        min-width="100"
        fixed="right"
      >
        <template v-slot="scope">
          <el-switch
            v-model="scope.row.status"
            active-color="#18bc9c"
            inactive-color="#d2d6de"
            :active-value="1"
            :inactive-value="0"
            @change="toggleHandle(scope.row, 'status')"
          ></el-switch>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="createTime"
        label="创建时间"
        min-width="120"
        header-align="center"
        align="center"
        :formatter="
          (row) => $moment.unix(row.createTime).format('yyyy-MM-DD HH:mm:ss')
        "
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        label="更新时间"
        min-width="120"
        header-align="center"
        align="center"
        :formatter="
          (row) => $moment.unix(row.updateTime).format('yyyy-MM-DD HH:mm:ss')
        "
      ></el-table-column>
      <el-table-column
        label="操作"
        header-align="center"
        align="center"
        min-width="90"
      >
        <template v-slot="scope">
          <el-button
            class="icon-btn fa fa-pencil"
            type="primary"
            @click="editHandle(scope.row)"
          >
            <!-- 编辑 -->
          </el-button>
          <el-button
            class="icon-btn fa fa-trash"
            type="danger"
            @click="delHandle(scope.row)"
          >
            <!-- 删除 -->
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-model="paginationData"
      :total="total"
      @change="getList"
    ></pagination>
  </page-list>
</template>

<script>
import { msgErr, msgSuc } from "@/helpers/message";
import { listByPage, deleteById } from "@/apis/unidrink/channel";

const calls = [];
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    shopOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableData: [],
      loading: false,
      initForm: {
        shopId: [],
      },
      form: {},
      paginationData: {},
    };
  },
  emits: ["add", "edit"],
  created() {
    console.log(calls);
  },
  mounted() {},
  methods: {
    editHandle(row) {
      this.$emit("edit", row);
    },
    getList() {
      this.loading = true;
      this.form.type = this.type == "all" ? "" : this.type;
      listByPage({ ...this.form, ...this.paginationData })
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addHandle() {
      this.$emit("add");
    },

    delHandle(row) {
      this.$confirm("确定删除此项？", "温馨提示").then(() => {
        deleteById(row.id).then(({ code, result, message }) => {
          if (code === "0000") {
            msgSuc("操作成功！");
            this.getList();
          } else {
            msgErr(message);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-page_container {
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .panel-page_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
  }
}
.move-handle {
  background-color: #2c3e50;
  color: #ffffff;
}
</style>
