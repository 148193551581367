<template>
  <div class="uni-table-colunm_container">
    <el-table-column v-bind="$attrs" header-align="center" align="center">
    </el-table-column>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
