import { createRouter, createWebHistory } from "vue-router";
import _import from "./_import";

const layout = _import("global/layout");

/**
 * meta数据解释
 *    title: 菜单标题
 *    hidden: 是否展示在菜单中
 *    icon: 菜单图标，优先获取后台配置
 *    permissionId:权限ID，必须配置且与权限查询api返回结果相匹配才算拥有访问权限
 */

// 全局路由，无需权限
const globalRoutes = [
  {
    path: "/",
    component: layout,
    redirect: "/home",
    hidden: true,
    children: [
      {
        path: "home",
        component: _import("global/home"),
        meta: {
          title: "首页",
          icon: "",
        },
      },
    ],
  },
  {
    path: "/login",
    component: _import("global/login"),
    hidden: true,
    meta: {
      title: "登录",
      icon: "",
    },
  },
  {
    path: "/404",
    component: _import("global/404"),
    hidden: true,
    meta: {
      title: "404",
      icon: "",
    },
  },
];

/**
 * 以下路由需获取权限
 */
const authRoutes = [
  {
    path: "/auth",
    component: layout,
    children: [
      {
        path: "admin",
        component: _import("auth/admin"),
        meta: {
          title: "管理员管理",
          icon: "fa fa-user",
          permissionId: 9,
          keepAlive: true,
        },
      },
      {
        path: "shop",
        component: _import("auth/shop"),
        meta: {
          title: "门店管理",
          icon: "fa fa-shopping-bag",
          permissionId: 144,
          keepAlive: true,
        },
      },
      {
        path: "employee",
        component: _import("unidrink/employee"),
        meta: {
          title: "店员管理",
          icon: "fa fa-user",
          permissionId: 264,
          keepAlive: true,
        },
      },
      {
        path: "channel",
        component: _import("unidrink/channel"),
        meta: {
          title: "渠道二维码",
          icon: "fa fa-qrcode",
          permissionId: 269,
          keepAlive: true,
        },
      },
    ],
    meta: {
      title: "商户管理",
      icon: "fa fa-group",
      permissionId: 5,
    },
  },
];

const userRoutes = [
  {
    path: "/user",
    component: layout,
    children: [
      {
        path: "customer",
        component: _import("user/customer"),
        meta: {
          title: "会员管理",
          icon: "fa fa-user",
          permissionId: 67,
          keepAlive: true,
        },
      },
    ],
    meta: {
      title: "会员管理",
      icon: "fa fa-user-circle",
      permissionId: 66,
      keepAlive: true,
    },
  },
];

const unidrinkRoutes = [
  {
    path: "/unidrink",
    component: layout,
    children: [
      {
        path: "advert",
        component: _import("unidrink/advert"),
        meta: {
          title: "广告图管理",
          icon: "fa fa-buysellads",
          permissionId: 92,
          keepAlive: true,
        },
      },
      {
        path: "category",
        component: _import("unidrink/category"),
        meta: {
          title: "分类管理",
          icon: "fa fa-align-justify",
          permissionId: 98,
          keepAlive: true,
        },
      },
      {
        path: "config",
        component: _import("unidrink/config"),
        hidden: true,
        meta: {
          title: "系统配置",
          icon: "fa fa-certificate",
          permissionId: 104,
          keepAlive: true,
        },
      },
      {
        path: "coupon",
        component: _import("unidrink/coupon"),
        meta: {
          title: "优惠券管理",
          icon: "fa fa-gratipay",
          permissionId: 110,
          keepAlive: true,
        },
      },
      {
        path: "order",
        component: _import("unidrink/order"),
        meta: {
          title: "订单管理",
          icon: "fa fa-print",
          permissionId: 119,
          keepAlive: true,
        },
      },
      {
        path: "product",
        component: _import("unidrink/product"),
        meta: {
          title: "产品管理",
          icon: "fa fa-product-hunt",
          permissionId: 128,
          keepAlive: true,
        },
      },
      {
        path: "workspace",
        component: _import("unidrink/workspace"),
        hidden: true,
        meta: {
          title: "工作台",
          icon: "fa fa-table",
          permissionId: 156,
          keepAlive: true,
        },
      },
      {
        path: "dashboard",
        component: _import("unidrink/dashboard"),
        meta: {
          title: "仪表盘统计",
          icon: "fa fa-dashboard",
          permissionId: 177,
          keepAlive: true,
        },
      },
      {
        path: "shopbox",
        component: _import("unidrink/shopbox"),
        meta: {
          title: "商铺包厢",
          icon: "fa fa-users",
          permissionId: 237,
          keepAlive: true,
        },
      },
      {
        path: "channelqr",
        component: _import("unidrink/channelqr"),
        hidden: true,
        meta: {
          title: "渠道二维码",
          icon: "fa fa-qrcode",
          permissionId: 247,
          keepAlive: true,
        },
      },
    ],
    meta: {
      title: "点酒系统",
      icon: "fa fa-list",
      permissionId: 85,
      keepAlive: true,
    },
  },
];

const uniprintRoutes = [
  {
    path: "/uniprint",
    component: layout,
    // hidden: true,
    children: [
      {
        path: "printer",
        component: _import("uniprint/printer"),
        meta: {
          title: "打印机管理",
          icon: "fa fa-font-awesome",
          permissionId: 182,
          keepAlive: true,
        },
      },
      {
        path: "moulage",
        component: _import("uniprint/moulage"),
        meta: {
          title: "打印机模版",
          icon: "fa fa-font-awesome",
          permissionId: 189,
          keepAlive: true,
        },
      },
    ],
    meta: {
      title: "web云打印机",
      icon: "fa fa-font-awesome",
      permissionId: 181,
      keepAlive: true,
    },
  },
];

const pageRoutes = [
  ...authRoutes,
  ...userRoutes,
  ...unidrinkRoutes,
  ...uniprintRoutes,
];

export { globalRoutes, pageRoutes };

export default createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHistory(),
  routes: globalRoutes, // `routes: routes` 的缩写
});
