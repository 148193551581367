export function packFormUrl(form) {
  const cond = {};
  Object.keys(form).forEach((key) => {
    if (Array.isArray(form[key])) {
      // form[key].forEach()
      cond[`row[${key}]`] = form[key];
    } else {
      cond[`row[${key}]`] = form[key];
    }
  });
  return cond;
}

export function qsPack(form) {
  const arrs = [];
  Object.keys(form).forEach((key) => {
    if (!Array.isArray(form[key])) {
      arrs.push(`row[${key}]=${form[key]}`);
    } else {
      form[key].forEach((item) => {
        arrs.push(`row[${key}][]=${item}`);
      });
    }
  });
  return arrs.join("&");
}


export function pack(form) {
	// 用户id为1的拥有所有权限
	// let bossId = store.getters.userInfo.bossId
	// if (bossId == 1 && store.getters.userInfo.id == 1) {
	// 	bossId = form.bossId
	// }
	return {
		// head: {
		// 	userId: store.getters.userInfo.id,
		// 	bossId: store.getters.userInfo.bossId
		// },
		body: {
			...form,
			// bossId: bossId
		}
	}
}
