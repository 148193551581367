<template>
  <div class="uni-table_container">
    <el-table v-bind="$attrs">
      <slot></slot>
    </el-table>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
